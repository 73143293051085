import { PropsWithChildren } from "react";

/**
 * Renders the children only in development mode.
 *
 * Application is in development mode if NEXT_PUBLIC_MODE environment variable
 * is set to "development".
 */
export function DevelopmentOnly({
  children
}: PropsWithChildren<{}>) {
  if (process.env.NEXT_PUBLIC_MODE !== "development") {
    return null;
  }
  return <>{children}</>;
}